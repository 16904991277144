// libraries
import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Transition } from 'react-transition-group';

// components
import Navigation from 'components2/Navigation';
import Layout from 'components2/Layout';
import Button from 'components2/Button';
import Highlight from 'components2/Highlight';
import DrawSvg from 'components2/DrawSvg';
import ScaleIn from 'components2/ScaleIn';
import SubFooter from 'components2/SubFooter';
import { styled } from 'style2';
import { DOWNLOADS } from 'config';

// images
import heroGaming from 'images/home/heroGaming.png';
import heroGaming2x from 'images/home/heroGaming@2x.png';
import heroWarp from 'images/home/heroWarp.png';
import heroWarp2x from 'images/home/heroWarp@2x.png';
import heroTeams from 'images/home/heroTeams.png';
import heroTeams2x from 'images/home/heroTeams@2x.png';
import screenshotGame from 'images/home/screenshotGame.png';
import screenshotGame2x from 'images/home/screenshotGame@2x.png';
import screenshotWarp from 'images/home/screenshotWarp.png';
import screenshotWarp2x from 'images/home/screenshotWarp@2x.png';
import overlay1 from 'images/home/overlay1.svg';
import overlay2 from 'images/home/overlay2.png';
import overlay22x from 'images/home/overlay2@2x.png';
import overlay3 from 'images/home/overlay3.png';
import overlay32x from 'images/home/overlay3@2x.png';
import share from 'images/home/share.png';
import share2x from 'images/home/share@2x.png';
import team from 'images/home/team.png';
import team2x from 'images/home/team@2x.png';
import warp from 'images/home/warp.png';
import warp2x from 'images/home/warp@2x.png';
import hills from 'images/home/hills.png';
import hills2x from 'images/home/hills@2x.png';
import circle from 'images/home/circleWhite.svg';
import ex from 'images/home/exWhite.svg';
import star from 'images/home/starWhite.svg';

// hooks
import usePlatform from 'hooks/usePlatform';
import useIsVisible from 'hooks/useIsVisible';

type Pages = 'teams' | 'gaming' | 'warp';
const PAGES = ['teams', 'gaming', 'warp'] as const;

export default function Home(props: RouteComponentProps) {
  const params = new URLSearchParams(props.location?.search);
  const landing = params.get('landing') as Pages | null;
  const page = landing && PAGES.includes(landing) ? landing : undefined;

  return (
    <Layout>
      <Wrapper>
        <Hero page={page} />
        <Main>
          <KeepYourTeamConnected />
          <PlayTogetherFromAnywhere />
          <CustomizeYourSetup />
        </Main>

        <Footer />
      </Wrapper>
    </Layout>
  );
}

function Hero(props: { page?: Pages }) {
  const platform = usePlatform();

  const shouldTransition = !props.page;
  const [page, setPage] = useState(props.page || 'teams');

  useEffect(() => {
    if (!shouldTransition) return;

    const interval = setInterval(() => {
      setPage(page => {
        const index = PAGES.indexOf(page) + 1;

        if (index === PAGES.length) return PAGES[0];
        else return PAGES[index];
      });
    }, 5_000);

    return () => clearInterval(interval);
  }, [shouldTransition]);

  return (
    <HeroWrapper>
      <StyledNavigation color="light" />

      <HeroContent>
        <HeroTitle>
          <span>Connect to</span>
          <Transition in={page === 'gaming'} timeout={500}>
            {state => <Use status={state}>games</Use>}
          </Transition>
          <Transition in={page === 'teams'} timeout={500}>
            {state => <Use status={state}>your team</Use>}
          </Transition>
          <Transition in={page === 'warp'} timeout={500}>
            {state => <Use status={state}>creations</Use>}
          </Transition>
        </HeroTitle>

        {shouldTransition ? (
          <HeroSubtitle>
            Parsec connects you to your work, games, and friends wherever you
            are, <SubtitleBreak />
            across any device in crystal clear, interactive HD.
          </HeroSubtitle>
        ) : page === 'warp' ? (
          <HeroSubtitle>
            Parsec connects you to your PC games so you can play{' '}
            <SubtitleBreak /> from anywhere, in crystal clear, interactive HD.
          </HeroSubtitle>
        ) : page === 'teams' ? (
          <HeroSubtitle>
            Parsec for Teams lets businesses create & collaborate{' '}
            <SubtitleBreak /> from anywhere with the most powerful remote access
            tools out there.
          </HeroSubtitle>
        ) : (
          <HeroSubtitle>
            Parsec connects you to your PC games so you can play{' '}
            <SubtitleBreak /> from anywhere, in crystal clear, interactive HD.
          </HeroSubtitle>
        )}

        {page === 'warp' ? (
          <Button href="/warp" kind="brand">
            {shouldTransition ? 'Get Started' : 'Show Me Warp'}
          </Button>
        ) : page === 'teams' ? (
          <Button href="/teams" kind="brand">
            {shouldTransition ? 'Get Started' : 'Learn More'}
          </Button>
        ) : (
          <Button
            href={(platform && DOWNLOADS[platform]) || '/downloads'}
            kind="brand"
          >
            {shouldTransition ? 'Get Started' : 'Download for Free'}
          </Button>
        )}
      </HeroContent>

      <Transition
        in={page === 'gaming'}
        timeout={500}
        mountOnEnter
        unmountOnExit
      >
        {state => <HeroGaming status={state} />}
      </Transition>
      <Transition
        in={page === 'teams'}
        timeout={500}
        mountOnEnter
        unmountOnExit
      >
        {state => <HeroTeams status={state} />}
      </Transition>
      <Transition in={page === 'warp'} timeout={500} mountOnEnter unmountOnExit>
        {state => <HeroWarp status={state} />}
      </Transition>

      <Screenshot page={page} />
    </HeroWrapper>
  );
}

function Screenshot(props: { page: Pages }) {
  const { page } = props;

  const [ref, visible] = useIsVisible<HTMLDivElement>({
    threshold: 0.33
  });

  return (
    <ScreenshotWrapper ref={ref}>
      <Transition in={page === 'gaming'} timeout={500}>
        {state => (
          <ScreenshotApp
            visible={state === 'entered' || state === 'entering'}
            src={screenshotGame}
            srcSet={`${screenshotGame} 1x, ${screenshotGame2x} 2x`}
            alt=""
          />
        )}
      </Transition>

      <Transition in={page === 'warp' || page === 'teams'} timeout={500}>
        {state => (
          <ScreenshotApp
            visible={state === 'entered' || state === 'entering'}
            src={screenshotWarp}
            srcSet={`${screenshotWarp} 1x, ${screenshotWarp2x} 2x`}
            alt=""
          />
        )}
      </Transition>

      <Scale
        scale="small"
        visible={visible}
        css={{ width: '4.5%', top: '14.4%', left: '3.2%' }}
      >
        <ScreenshotOverlay src={overlay1} alt="" />
      </Scale>
      <Scale
        scale="small"
        delay={100}
        visible={visible}
        css={{ width: '29.1%', top: '14.4%', left: '8.5%' }}
      >
        <ScreenshotOverlay
          src={overlay2}
          srcSet={`${overlay2} 1x, ${overlay22x} 2x`}
          alt=""
        />
      </Scale>
      <Scale
        scale="small"
        delay={200}
        visible={visible}
        css={{ width: '14.25%', top: '32.4%', left: '38.3%' }}
      >
        <ScreenshotOverlay
          src={overlay3}
          srcSet={`${overlay3} 1x, ${overlay32x} 2x`}
          alt=""
        />
      </Scale>
    </ScreenshotWrapper>
  );
}

type TransitionStatus =
  | 'entering'
  | 'entered'
  | 'exiting'
  | 'exited'
  | 'unmounted';

function HeroGaming(props: { status: TransitionStatus }) {
  const { status } = props;

  const [ref, visible] = useIsVisible<HTMLDivElement>({
    threshold: 0.1
  });

  return (
    <HeroBackground image="gaming" ref={ref} status={status}>
      <HeroGraphic>
        <DrawSvg visible={visible}>
          <Svg
            css={{ top: '25.4rem', right: '36.8rem' }}
            xmlns="http://www.w3.org/2000/svg"
            width="191.872"
            height="178.271"
            viewBox="0 0 191.872 178.271"
          >
            <path
              d="M2.312 98.034c16.991-25.486 53.225-19.908 75.43-5.257a59.733 59.733 0 0117.315 16.805c5.86 8.97 8.68 20.133 6.564 30.634-1.591 7.884-6.454 14.203-14.974 14.355-5.99.105-11.703-2.881-16.098-6.953-28.375-26.285-32.428-79.714 1.755-103.229 15.45-10.628 37.368-11.854 52.977-1.457 6.056 4.034 11.282 10.12 12.054 17.355.733 6.878-3.98 14.938-11.29 15.663-8.258.82-14.86-8.222-16.828-15.38-1.983-7.213-.807-15.05 2.17-21.915C116.81 26.146 127.22 16.86 137.4 8.23"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </Svg>
        </DrawSvg>

        <Scale
          css={{ top: '44.9rem', right: '45.6rem' }}
          delay={600}
          visible={visible}
        >
          <img src={star} alt="" />
        </Scale>

        <Scale
          css={{ top: '17.1rem', left: '14.1rem' }}
          delay={1200}
          visible={visible}
        >
          <img src={star} alt="" />
        </Scale>

        <Scale
          css={{ top: '20.4rem', left: '10.8rem' }}
          delay={1350}
          visible={visible}
        >
          <img src={circle} alt="" />
        </Scale>

        <DrawSvg duration={250} delay={600} visible={visible}>
          <Svg
            css={{ top: '36rem', left: '33.7rem' }}
            xmlns="http://www.w3.org/2000/svg"
            width="44.466"
            height="65.405"
            viewBox="0 0 44.466 65.405"
          >
            <path
              d="M.59 60.888c10.16 1.78 20.805-2.203 28.48-9.092 6.89-6.185 11.724-14.867 12.381-24.102s-3.18-18.888-10.59-24.441"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </Svg>
        </DrawSvg>

        <Scale
          css={{ top: '31.6rem', left: '33.2rem' }}
          delay={900}
          visible={visible}
        >
          <img src={circle} alt="" />
        </Scale>
      </HeroGraphic>
    </HeroBackground>
  );
}

function HeroWarp(props: { status: TransitionStatus }) {
  const { status } = props;

  const [ref, visible] = useIsVisible<HTMLDivElement>({
    threshold: 0.1
  });

  return (
    <HeroBackground image="warp" ref={ref} status={status}>
      <HeroGraphic>
        <DrawSvg visible={visible}>
          <Svg
            css={{ top: '13rem', right: '11rem' }}
            width="258"
            height="92"
            viewBox="0 0 258 92"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M256.93 33.49c-11.651-3.006-22.355-5.08-34.182-1.292-6.48 2.079-12.862 6.949-15.922 13.125-3.159 6.381-2.646 19.002 7.066 18.898 10.35-.11 12.458-12.629 10.965-20.67-7.458-40.157-78.3-49.686-100.779-17.986-3.958 5.58-5.586 13.118-2.96 19.437 2.624 6.318 10.07 10.641 16.606 8.612 6.469-2.006 10.138-9.414 9.629-16.17-1.711-22.692-31.013-35.316-50.537-36.333C75.704.011 54.183 9.348 43.252 27.97a66.98 66.98 0 00-8.861 34.989c.297 9.512 4.847 28.427 17.523 27.815 7.98-.378 11.616-8.511 11.257-15.55-.682-13.212-14.338-24.439-26.521-26.578C23.188 46.282 8.82 52.667.783 63.721"
              stroke="#FFF"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </Svg>
        </DrawSvg>

        <Scale
          css={{ top: '24.8rem', right: '34.2rem' }}
          visible={visible}
          delay={600}
        >
          <img src={ex} alt="" />
        </Scale>

        <Scale
          css={{ top: '43.4rem', right: '43.5rem' }}
          visible={visible}
          delay={750}
        >
          <img src={star} alt="" />
        </Scale>

        <Scale
          css={{ top: '46.8rem', left: '41.5rem' }}
          visible={visible}
          delay={1050}
        >
          <img src={star} alt="" />
        </Scale>

        <Scale
          css={{ top: '52.9rem', left: '45.8rem' }}
          visible={visible}
          delay={1200}
        >
          <img src={circle} alt="" />
        </Scale>
      </HeroGraphic>
    </HeroBackground>
  );
}

function HeroTeams(props: { status: TransitionStatus }) {
  const { status } = props;
  const [ref, visible] = useIsVisible<HTMLDivElement>({
    threshold: 0.1
  });

  return (
    <HeroBackground image="teams" ref={ref} status={status}>
      <HeroGraphic>
        <DrawSvg visible={visible}>
          <Svg
            css={{ top: '25.6rem', right: '36rem' }}
            width="139"
            height="126"
            viewBox="0 0 139 126"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M138.116 85.437c-22.947-11.068-51.657-.512-64.856 20.832-2.982 4.82-5.034 11.499-1.338 15.791 5.363 6.228 14.525 1.107 18.882-3.515 5.442-5.772 8.692-13.45 9.973-21.218C104.419 75.2 96.56 51.113 79.408 36.48c-15.285-13.032-36.074-15.874-53.437-5.106C12 40.034 1.921 56.485.812 72.948c-.625 9.29 4.982 16.557 14.849 15.434 14.253-1.624 25.152-15.59 28.256-28.766C48.952 38.238 37.71 17.278 25.49.542"
              stroke="#FFF"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </Svg>
        </DrawSvg>

        <Scale
          css={{ top: '40.4rem', right: '43.2rem' }}
          visible={visible}
          delay={750}
        >
          <img src={star} alt="" />
        </Scale>

        <Scale
          css={{ top: '19rem', left: '24.4rem' }}
          visible={visible}
          delay={900}
        >
          <img src={star} alt="" />
        </Scale>

        <Scale
          css={{ top: '23.1rem', left: '29.5rem' }}
          visible={visible}
          delay={1050}
        >
          <img src={ex} alt="" />
        </Scale>

        <DrawSvg visible={visible} delay={900}>
          <Svg
            css={{ top: '49.1rem', left: '37rem' }}
            width="96"
            height="87"
            viewBox="0 0 96 87"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M90.413 1.2c6.133 12.238 6.808 25.693-.436 37.5C76.28 61.03 44.237 67.546 21.15 57.089 11.371 52.658 2.678 43.635.922 32.744c-1.187-7.35 2.709-13.384 9.99-15.107a26.708 26.708 0 0111.436.223 51.985 51.985 0 0135.025 26.092c7.05 13.203 8.675 30.081-2.388 41.67"
              stroke="#FFF"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </Svg>
        </DrawSvg>

        <Scale
          css={{ top: '61.1rem', left: '39.8rem' }}
          visible={visible}
          delay={1800}
        >
          <img src={circle} alt="" />
        </Scale>
      </HeroGraphic>
    </HeroBackground>
  );
}

function KeepYourTeamConnected() {
  const [ref, visible] = useIsVisible<HTMLDivElement>({
    threshold: 0.1
  });

  return (
    <Highlight
      ref={ref}
      title="Keep your team connected"
      graphicHeight="33.2rem"
      graphic={
        <TeamsIllustration>
          <DrawSvg duration={1000} stagger={50} visible={visible}>
            <svg
              width="480"
              height="240"
              viewBox="0 0 480 240"
              xmlns="http://www.w3.org/2000/svg"
            >
              {/* prettier-ignore */}
              <path d="M3.78.5h472.44c1.811 0 3.28 1.349 3.28 3.013v232.974c0 1.664-1.469 3.013-3.28 3.013H3.78c-1.811 0-3.28-1.349-3.28-3.013V3.513C.5 1.849 1.969.5 3.78.5z" fill="none" stroke="#0c0c0d" />
              <path d="M80 0v240" stroke="#0c0c0d" />
              <path d="M160 0v240" stroke="#0c0c0d" />
              <path d="M240 0v240" stroke="#0c0c0d" />
              <path d="M320 0v240" stroke="#0c0c0d" />
              <path d="M400 0v240" stroke="#0c0c0d" />
              <path d="M40 0v240" stroke="#0c0c0d" />
              <path d="M120 0v240" stroke="#0c0c0d" />
              <path d="M200 0v240" stroke="#0c0c0d" />
              <path d="M280 0v240" stroke="#0c0c0d" />
              <path d="M360 0v240" stroke="#0c0c0d" />
              <path d="M440 0v240" stroke="#0c0c0d" />
              <path d="M480 80H0" stroke="#0c0c0d" />
              <path d="M480 160H0" stroke="#0c0c0d" />
              <path d="M480 40H0" stroke="#0c0c0d" />
              <path d="M480 120H0" stroke="#0c0c0d" />
              <path d="M480 200H0" stroke="#0c0c0d" />
            </svg>
          </DrawSvg>

          <Scale
            css={{ top: '5.2rem', left: '4.1rem' }}
            scale="small"
            visible={visible}
            delay={600}
          >
            <img src={team} srcSet={`${team} 1x, ${team2x} 2x`} alt="" />
          </Scale>

          <Scale
            css={{ top: '2.1rem', left: '35.6rem' }}
            visible={visible}
            delay={800}
          >
            <img src={star} alt="" />
          </Scale>

          <Scale
            css={{ top: '4.2rem', left: '39.6rem' }}
            visible={visible}
            delay={950}
          >
            <img src={circle} alt="" />
          </Scale>

          <Scale
            css={{ top: '31.2rem', left: '8.1rem' }}
            visible={visible}
            delay={1100}
          >
            <img src={ex} alt="" />
          </Scale>
        </TeamsIllustration>
      }
    >
      <HighlightText>
        We’re the ultimate remote desktop, freeing developers, designers,
        architects, and engineers to create from anywhere.
      </HighlightText>
      <Button href="/teams" level="secondary" kind="inverted">
        Parsec for Teams
      </Button>
    </Highlight>
  );
}

function PlayTogetherFromAnywhere() {
  const [ref, visible] = useIsVisible<HTMLDivElement>({
    threshold: 0.1
  });

  return (
    <Highlight
      ref={ref}
      title="Play together from anywhere"
      align="right"
      graphicHeight="29.6rem"
      graphic={
        <FeaturesIllustration>
          <DrawSvg duration={1000} stagger={50} visible={visible}>
            <Svg
              css={{ left: '11.2rem' }}
              width="440"
              height="240"
              viewBox="0 0 440 240"
              xmlns="http://www.w3.org/2000/svg"
            >
              {/* prettier-ignore */}
              <path d="M3.507.5h432.986a3.01 3.01 0 013.007 3.013v232.974a3.01 3.01 0 01-3.007 3.013H3.507A3.01 3.01 0 01.5 236.487V3.513A3.01 3.01 0 013.507.5z" fill="none" stroke="#0C0C0D"/>
              <path d="M80 0v240" stroke="#0C0C0D" />
              <path d="M160 0v240" stroke="#0C0C0D" />
              <path d="M240 0v240" stroke="#0C0C0D" />
              <path d="M320 0v240" stroke="#0C0C0D" />
              <path d="M400 0v240" stroke="#0C0C0D" />
              <path d="M40 0v240" stroke="#0C0C0D" />
              <path d="M120 0v240" stroke="#0C0C0D" />
              <path d="M200 0v240" stroke="#0C0C0D" />
              <path d="M280 0v240" stroke="#0C0C0D" />
              <path d="M360 0v240" stroke="#0C0C0D" />
              <path d="M440 80H0" stroke="#0C0C0D" />
              <path d="M440 160H0" stroke="#0C0C0D" />
              <path d="M440 40H0" stroke="#0C0C0D" />
              <path d="M440 120H0" stroke="#0C0C0D" />
              <path d="M440 200H0" stroke="#0C0C0D" />
            </Svg>
          </DrawSvg>

          <Scale
            css={{ top: '6.1rem' }}
            delay={600}
            scale="small"
            visible={visible}
          >
            <img src={share} srcSet={`${share} 1x, ${share2x} 2x`} alt="" />
          </Scale>

          <Scale
            css={{ top: '4.7rem', left: '4.6rem' }}
            delay={800}
            visible={visible}
          >
            <img src={star} alt="" />
          </Scale>

          <Scale
            css={{ top: '8.5rem', left: '2.6rem' }}
            delay={950}
            visible={visible}
          >
            <img src={circle} alt="" />
          </Scale>

          <Scale
            css={{ top: '7.5rem', left: '47rem' }}
            delay={1100}
            visible={visible}
          >
            <img src={star} alt="" />
          </Scale>
        </FeaturesIllustration>
      }
    >
      <HighlightText>
        Parsec’s fine tuned for playing games. Use it to connect to your own
        computer, share a link to play with a friend, or hop into a game in the
        Parsec Arcade.
      </HighlightText>
      <Button href="/features" level="secondary" kind="inverted">
        Show me features
      </Button>
    </Highlight>
  );
}

function CustomizeYourSetup() {
  const [ref, visible] = useIsVisible<HTMLDivElement>({
    threshold: 0.1
  });

  return (
    <Highlight
      ref={ref}
      title="Customize your setup"
      graphicHeight="35rem"
      graphic={
        <WarpIllustration>
          <DrawSvg duration={1000} stagger={50} visible={visible}>
            <Svg
              css={{ top: '7rem', left: '23.2rem' }}
              width="280"
              height="280"
              viewBox="0 0 280 280"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M80 0v280" stroke="#0C0C0D" />
              <path d="M160 0v280" stroke="#0C0C0D" />
              <path d="M240 0v280" stroke="#0C0C0D" />
              <path d="M40 0v280" stroke="#0C0C0D" />
              <path d="M120 0v280" stroke="#0C0C0D" />
              <path d="M200 0v280" stroke="#0C0C0D" />
              <path d="M280 80H0" stroke="#0C0C0D" />
              <path d="M280 160H0" stroke="#0C0C0D" />
              <path d="M280 240H0" stroke="#0C0C0D" />
              <path d="M280 40H0" stroke="#0C0C0D" />
              <path d="M280 120H0" stroke="#0C0C0D" />
              <path d="M280 200H0" stroke="#0C0C0D" />
              {/* prettier-ignore */}
              <path d="M2.411.5h275.178c1.055 0 1.911 1.575 1.911 3.517v271.966c0 1.942-.856 3.517-1.911 3.517H2.411C1.356 279.5.5 277.925.5 275.983V4.017C.5 2.075 1.356.5 2.411.5z" fill="none" stroke="#0C0C0D" />
            </Svg>
          </DrawSvg>

          <Scale
            css={{ top: '3.9rem' }}
            delay={600}
            scale="small"
            visible={visible}
          >
            <img src={warp} srcSet={`${warp} 1x, ${warp2x} 2x`} alt="" />
          </Scale>

          <DrawSvg delay={600} visible={visible}>
            <Svg
              css={{ left: '37.2rem', top: '1rem' }}
              width="88"
              height="67"
              viewBox="0 0 88 67"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M87.21 65.651c.139-18.711-9.085-37.322-25.434-46.935-12.038-7.075-29.471-9.412-41.9-1.988C10.66 22.232 3.8 37.463 9.598 47.59c3.55 6.206 10.923 6.09 14.919.681 3.522-4.767 5.384-11.955 5.142-17.85C29.07 16.042 17.876-2.375 1.283 1.358"
                stroke="#FFF"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </Svg>
          </DrawSvg>

          <Scale css={{ left: '33.3rem' }} delay={1200} visible={visible}>
            <img src={star} alt="" />
          </Scale>

          <Scale
            css={{ top: '32.2rem', left: '15.5rem' }}
            delay={1400}
            visible={visible}
          >
            <img src={star} alt="" />
          </Scale>
        </WarpIllustration>
      }
    >
      <HighlightText>
        We’re built for gaming, and that makes us perfect for basically
        anything. Upgrade to Parsec Warp for advanced controls, settings, and
        visual enhancements.
      </HighlightText>
      <Button href="/warp" level="secondary" kind="inverted">
        Parsec Warp
      </Button>
    </Highlight>
  );
}

function Footer() {
  const platform = usePlatform();
  const [ref, visible] = useIsVisible<HTMLDivElement>({
    threshold: 0.1
  });

  return (
    <SubFooter
      ref={ref}
      title="See it for yourself. Download for free and try Parsec."
      actions={
        <Button href={(platform && DOWNLOADS[platform]) || '/downloads'}>
          Download Now
        </Button>
      }
      backgroundColor="#f62e6a"
      backgroundImage={hills}
      backgroundImageRetina={hills2x}
      backgroundSize="75rem"
      backgroundPosition="bottom -16rem right"
    >
      <DrawSvg visible={visible}>
        <Svg
          css={{ bottom: '33.8rem', right: '52.6rem' }}
          width="81"
          height="73"
          viewBox="0 0 81 73"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M.477 32.83C4.051 21.406 11.804 12.531 23.535 9.505c22.182-5.722 46.295 9.647 54.46 30.253 3.457 8.723 3.444 19.682-2.068 27.6-3.722 5.346-9.836 6.773-15.46 3.432a23.36 23.36 0 01-7.059-7.089 45.461 45.461 0 01-6.175-37.69C50.819 13.424 60.052 1.82 74.053 1.255"
            stroke="#FFF"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </Svg>
      </DrawSvg>

      <Scale
        css={{ bottom: '43.4rem', right: '49.9rem' }}
        visible={visible}
        delay={750}
      >
        <img src={star} alt="" />
      </Scale>

      <Scale
        css={{ bottom: '32.7rem', right: '59rem' }}
        visible={visible}
        delay={900}
      >
        <img src={circle} alt="" />
      </Scale>

      <Scale
        css={{ bottom: '43.4rem', right: '25.1rem' }}
        visible={visible}
        delay={1050}
      >
        <img src={circle} alt="" />
      </Scale>

      <Scale
        css={{ bottom: '37.6rem', right: '7.8rem' }}
        visible={visible}
        delay={1200}
      >
        <img src={star} alt="" />
      </Scale>

      <Scale
        css={{ bottom: '43.9rem', right: '6.3rem' }}
        visible={visible}
        delay={1350}
      >
        <img src={ex} alt="" />
      </Scale>
    </SubFooter>
  );
}

const StyledNavigation = styled(Navigation, {
  position: 'relative',
  zIndex: 10
});

const Wrapper = styled('div', {
  overflowX: 'hidden'
});

const HeroWrapper = styled('div', {
  position: 'relative',
  backgroundColor: '$pitchBlack',
  color: '$consoleWhite',
  paddingBottom: 'min(41.7vw, 51rem)',
  zIndex: 2
});

const HeroBackground = styled('div', {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: '9rem',
  backgroundPosition: 'top 2.5rem center',
  opacity: 0,
  transform: 'translateY(-6rem)',
  transition: 'opacity 0.25s ease, transform 0.5s ease-in-out',
  transitionDelay: '125ms',
  zIndex: 1,
  variants: {
    image: {
      gaming: {
        background: `url("${heroGaming}") no-repeat`,
        backgroundSize: '95rem',
        retina: {
          backgroundImage: `url("${heroGaming2x}")`
        }
      },
      warp: {
        background: `url("${heroWarp}") no-repeat`,
        backgroundSize: '90rem',
        retina: {
          backgroundImage: `url("${heroWarp2x}")`
        }
      },
      teams: {
        background: `url("${heroTeams}") no-repeat`,
        backgroundSize: '84rem',
        retina: {
          backgroundImage: `url("${heroTeams2x}")`
        }
      }
    },
    status: {
      entering: {
        opacity: 1,
        transform: 'translateY(0)'
      },
      entered: {
        opacity: 1,
        transform: 'translateY(0)'
      },
      exiting: {
        opacity: 0,
        transform: 'translateY(3rem)'
      },
      exited: {
        opacity: 0,
        transform: 'translateY(-3rem)'
      },
      unmounted: {}
    }
  }
});

const HeroContent = styled('div', {
  textAlign: 'center',
  padding: '15rem 2rem 14rem',
  position: 'relative',
  zIndex: 3
});

const HeroTitle = styled('h1', {
  position: 'relative',
  fontSize: '$h2',
  lineHeight: '5rem',
  medium: {
    fontSize: '9.6rem',
    lineHeight: '9rem'
  }
});

const HeroSubtitle = styled('p', {
  fontSize: '$small',
  lineHeight: '$small',
  margin: '3rem 0 4rem',
  medium: {
    fontSize: '$body',
    lineHeight: '$body'
  }
});

const SubtitleBreak = styled('br', {
  display: 'none',
  large: {
    display: 'block'
  }
});

const HeroGraphic = styled('div', {
  position: 'absolute',
  top: '-9rem',
  left: '50%',
  pointerEvents: 'none'
});

const Use = styled('span', {
  display: 'block',
  color: '#ffae11',
  position: 'relative',
  width: '100%',
  transition: 'opacity 0.5s ease, transform 0.5s ease-in-out',
  variants: {
    status: {
      entering: {
        opacity: 1,
        transform: 'translateY(0)'
      },
      entered: {
        opacity: 1,
        transform: 'translateY(0)'
      },
      exiting: {
        position: 'absolute',
        top: '5rem',
        opacity: 0,
        transform: 'translateY(2rem)',
        medium: {
          top: '9rem'
        }
      },
      exited: {
        position: 'absolute',
        top: '5rem',
        opacity: 0,
        transform: 'translateY(-2rem)',
        medium: {
          top: '9rem'
        }
      },
      unmounted: {}
    }
  }
});

const ScreenshotWrapper = styled('div', {
  position: 'absolute',
  width: 'min(90vw, 110rem)',
  paddingTop: 'min(53.1%, 65rem)',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 3
});

const ScreenshotApp = styled('img', {
  position: 'absolute',
  width: '100%',
  top: 0,
  opacity: 0,
  transition: 'opacity 0.5s ease',
  variants: {
    visible: {
      true: {
        opacity: 1
      }
    }
  }
});

const ScreenshotOverlay = styled('img', {
  width: '100%',
  borderRadius: '0.4rem',
  boxShadow: '0 0.2rem 0.6rem rgba(0, 0, 0, 0.3)'
});

const Main = styled('main', {
  position: 'relative',
  display: 'grid',
  gridAutoFlow: 'row',
  rowGap: '15rem',
  padding: 'calc(10vw + 16rem) 4rem 16rem',
  backgroundColor: '$computerBlack',
  color: '$consoleWhite',
  zIndex: 1
});

const HighlightText = styled('p', {
  fontSize: '$small',
  lineHeight: '$small',
  marginBottom: '4rem',
  medium: {
    fontSize: '$body',
    lineHeight: '$body'
  }
});

const Illustration = styled('div', {
  position: 'absolute',
  left: '50%',
  transformOrigin: 'top',
  transform: 'translateX(-50%) scale(0.6)',
  medium: {
    transform: 'translateX(-50%)'
  },
  full: { transform: 'none' }
});

const TeamsIllustration = styled(Illustration, {
  width: '54.1rem',
  top: '2rem',
  full: { left: '10.2rem' }
});

const FeaturesIllustration = styled(Illustration, {
  width: '55.2rem',
  top: '3rem',
  full: {
    left: 'initial',
    right: '10.2rem'
  }
});

const WarpIllustration = styled(Illustration, {
  width: '52.2rem',
  top: '1.9rem',
  full: { left: '10.2rem' }
});

const Svg = styled('svg', {
  position: 'absolute'
});

const Scale = styled(ScaleIn, {
  position: 'absolute'
});
